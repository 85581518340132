<template>
  <b-card-code
    title="驾照分历史"
    no-body
  >
    <b-card-body>
      <b-form-group
        label-cols-sm="10"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-2"
      >
        <b-input-group size="sm">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="输入搜索内容"
          />
        </b-input-group>
      </b-form-group>

      <b-table
        responsive
        class="mb-0"
        :items="items"
        :fields="fields"
        :bordered="bordered"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :tbody-tr-class="setBgColor"
        @filtered="onFiltered"
      >
        <template #cell(Driver)="data">
          <h-link
            v-if="data.item.Did > 0"
            :id="data.item.Did"
            :text="data.item.Driver"
            to="driver"
            type="driver"
          />
        </template>

        <template #cell(TargetDriver)="data">
          <h-link
            v-if="data.item.TargetDid >= 0"
            :id="data.item.TargetDid"
            :text="data.item.TargetDriver"
            to="driver"
            type="driver"
          />
        </template>

        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap">

        <b-form-group
          label="每页显示："
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>

    </b-card-body>
  </b-card-code>
</template>

<script>
import Vue from 'vue'
import axios from '@axios'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BCardBody, BFormSelect, BPagination, BFormGroup, BInputGroup,
  BFormInput, BInputGroupPrepend,
} from 'bootstrap-vue'
import HLink from '@/views/common/h-link.vue'

export default {
  components: {
    BCardCode,
    BTable,
    BCardBody,
    BFormSelect,
    BPagination,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    HLink,
  },
  data() {
    return {
      history: this.pdriverhistory,
      fields: [
        { key: 'Date', label: '发生日期', sortable: true },
        { key: 'Driver', label: '责任人' },
        { key: 'TargetDriver', label: '发起方', sortable: true },
        { key: 'RaceName', label: '比赛场次', sortable: true },
        { key: 'Incident', label: '事件', sortable: true },
        { key: 'Penalty', label: '扣分', sortable: true },
      ],
      items: [],
      striped: true,
      bordered: true,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      filter: null,
      filterOn: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    axios.get(`${Vue.prototype.$Api_Demerits_All}`)
      .then(response => {
        this.items = response.data
        this.totalRows = this.items.length
      })
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    setBgColor(item, type) {
      let content = ''
      if (!item || type !== 'row') {
        content = ''
      } else {
        switch (item.Flag) {
          case 3:
            content = 'primary bg-success bg-lighten-4'
            break
          case 2:
            content = 'bg-danger bg-lighten-4'
            break
          case 1:
            content = 'bg-warning bg-lighten-4'
            break
          default:
            content = ''
            break
        }
      }
      return content
    },
  },
}
</script>
